class Smoothscroll {
	constructor() {
		const links = document.getElementsByTagName('a');
		let idx = 0;
		for (idx; idx < links.length; idx++){
			const href = links[idx].hash;
			if(Array.from(href)[0] === '#') {
				const element = document.getElementById(href.substring(1))
				if(element) {
					links[idx].addEventListener("click", (e) => {
						e.preventDefault();
						element.scrollIntoView({ behavior: 'smooth', block: 'start'});
					});
				}
			}
		}
	}
}
