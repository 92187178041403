class Menu {
	constructor() {
		const OFFCANVASICON = document.getElementsByClassName("offcanvas-icon")[0];
		const OFFCANVAS = document.getElementsByTagName("nav")[1];
		OFFCANVASICON.addEventListener("click", ()=> {
			OFFCANVAS.classList.toggle("active");
			OFFCANVASICON.classList.toggle("active");
		})
	}
}
