class Header {
	constructor() {
		const HEADER = document.getElementsByTagName("header")[0];
		document.addEventListener("scroll", () => {
			if (window.scrollY > 10) {
				HEADER.classList.add("active")
			} else {
				HEADER.classList.remove("active")
			}
		});
	}
}
